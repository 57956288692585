@font-face {
  font-family: 'DefaultCustomFont';
  src: local('DefaultCustomFont'), url(fonts/WorkSans-VariableFont_wght.ttf) format('truetype');
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  
  font-family: DefaultCustomFont, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-variation-settings: 'wght' 350;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #FFFFFFE3;
}

html, body {
  min-height: 100%;
  background: linear-gradient(165deg, #00010f 0%, #071a53 50%, #4c218a 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

a {
  color: #FFF;
}

*{
  scrollbar-width: thin;
  scrollbar-color: #ffffff66 #ffffff11;
}

*::-webkit-scrollbar {
  width: 8px;
}
   
*::-webkit-scrollbar-track {
  background-color: #ffffff11;
  border-radius: 4px;
}   
   
*::-webkit-scrollbar-thumb {
  background-color: #ffffff66;
  border-radius: 4px;
}

  
.light {
  scrollbar-color: #00000066 #00000011;
}
.light::-webkit-scrollbar-track {
  background-color: #00000011;
  border-radius: 4px;
}   
   
.light::-webkit-scrollbar-thumb {
  background-color: #00000066;
  border-radius: 4px;
}
